/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'universal-cookie';
import { useAccount, useSwitchChain } from 'wagmi'
import { useAppKitAccount, useAppKitNetwork, useAppKit } from '@reown/appkit/react'
import '../App.css'
import InfoAbi from '../config/InfoAbi.json'
import ChadAbi from '../config/ChadPumpAbi.json'
import TokenAbi from '../config/TokenAbi.json'
import '../styles/MainContainer.css'
import MaxInput from '../components/MaxInput.tsx'
import { readContract, writeContract, simulateContract, waitForTransactionReceipt } from '@wagmi/core'
import Web3 from 'web3'
import { toast } from 'react-hot-toast'
import ClaimCard from '../components/ClaimCard.jsx'
import bnb from '../icons/ETH-logo.svg'
import arbitrum from '../icons/arbitrum.svg'
import eth from '../icons/ETH.svg'
import pol from '../icons/polygon.svg'
import tao from '../icons/tao.png'
import hotkey from '../icons/hotkeyswap.svg'
import sendIcon from '../icons/send-message.png'
import pancakeBannerImg from '../icons/pancake-banner.png'
import hotkeyswapBannerImg from '../icons/Hotkeyswapdex.png'
import swapIcon from '../icons/swapIcon.svg'
import TopBar from '../components/TopBar'
import ClipLoader from 'react-spinners/ClipLoader'
import { useQueryParam, StringParam } from 'use-query-params'
import MyChart from '../components/Chart.jsx'
import { SignMessage } from './SignMessage.jsx'
import CustomRadioButton from '../components/CustomRadioButton'
import CopyIcon from '../icons/copy.svg'
import rot13 from '../../utils/encode.ts'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { Link } from 'react-router-dom'
import { web3Clients, imageUrl, apiUrl, ethPriceApiUrl, scanApiLinks, apiKeys, scanLinks, chainNames, coinNames, mc, chainNames1 } from '../utils/constants.ts'
import { getDefaultAddress, getHotKeyAddress, getInfoAddress } from '../utils/addressHelpers.ts'
import { wagmiAdapter } from '../App.jsx';

const App = () => {
  const ethIcon = {
    1: eth,
    56: bnb,
    11155111: eth,
    8453: eth,
    137: pol,
    945: tao,
    42161: arbitrum
  }
  let [addressDatas] = useQueryParam('address', StringParam)
  const { switchChain } = useSwitchChain()
  let ChadAddress
  let ref
  if (addressDatas.includes('/?ref=')) {
    ChadAddress = addressDatas.split('/?ref=')[0]
    ref = addressDatas.split('/?ref=')[1]
  } else {
    ChadAddress = addressDatas
  }

  const { address, isConnected } = useAppKitAccount()
  const { chainId: currentChainId } = useAppKitNetwork()
  const { open } = useAppKit()
  let [chainId] = useQueryParam('chain', StringParam);
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [maxLimit, setMaxLimit] = useState(1)
  const [tokenAddress, setTokenAddress] = useState('')
  const tokenLogo = imageUrl + ChadAddress + '-logo.png'
  const tokenBanner = imageUrl + ChadAddress + '-banner.png'
  const [virtualLp, setVirtualLiquidiity] = useState(0)
  const [maxBuyAmount, setMaxBuyAmount] = useState(0)
  const [chatHistory, setChatHistory] = useState([])
  const [chatContent,] = useState('')
  const [tokenAmount, setAmount] = useState()
  const [tokenOutAmount, setTokenOutAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  let [inputBalance, setInputBalance] = useState(0)
  let [tokenBalance, setTokenBalance] = useState(0)
  let [tokenAllowance, setTokenAllowance] = useState(0)
  const [virtualTokenLp, setVirtualTokenLp] = useState()
  const [tokenPrice, setTokenPrice] = useState(0)
  let [creating, setCreating] = useState(false)
  let [collecting, setCollecting] = useState(false)
  const [website, setWebsite] = useState()
  const [twitter, setTwitter] = useState()
  const [telegram, setTelegram] = useState()
  const [inputToken, setInputToken] = useState('ETH')
  const [tokenHolders, setTokenHolders] = useState([])
  const [holderDatas, setTokenHolderDatas] = useState()
  const [transactions, setTransactions] = useState([])
  const [transactionDatas, setTransactionDatas] = useState([])
  const [tokenPriceDatas, setTokenPriceDatas] = useState()
  const [accumFee, setAccumFee] = useState()
  const [volume, setVolume] = useState(0)
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)
  const [isTooltipDisplayed1, setIsTooltipDisplayed1] = useState(false)
  const [contractAddress, setContractAddress] = useState('')
  const [devAddress, setDevAddress] = useState('')
  const [description, setDescription] = useState('')

  const [refCounts, setRefCounts] = useState(0)
  const [totalRefAmounts, setTotalRefAmounts] = useState(0)
  const [refUserAmount, setRefUserAmout] = useState(0)

  const [lpCreated, setLpCreated] = useState(false)
  const [ethPrice, setEthPrice] = useState()

  const [messageText, setMessageText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [userChat, setUserChat] = useState([]);
  const [botChat, setBotChat] = useState([]);
  const [showChat, setShowChat] = useState(true);

  const [fee, setFee] = useState();

  const scrollContainer = useRef(null);
  const focus = useRef(null);

  const cookies = new Cookies();
  if (ref) {
    if (Web3.utils.isAddress(rot13(ref))) {
      cookies.set('ref', ref)
    }
  }
  let refAddress
  if (cookies.get('ref')) {
    if (Web3.utils.isAddress(rot13(cookies.get('ref')))) {
      refAddress = rot13(cookies.get('ref'))
    }
  } else {
    refAddress = getDefaultAddress()
  }
  const BASE_URL = 'https://hotcurves.fun/buy/?chain=' + chainId + '&address=' + ChadAddress
  const referlink = address ? `${BASE_URL}/?ref=${rot13(address)}` : `${BASE_URL}/?ref=`

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  const copyAddress1 = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip1()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  function displayTooltip1() {
    let timeoutId
    setIsTooltipDisplayed1(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed1(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onCollectFee = async () => {
    try {
      setCollecting(true);
      await writeContract(wagmiAdapter.wagmiConfig, {
        address: ChadAddress,
        abi: ChadAbi,
        functionName: 'collectFee',
        gas: 700000
      })
      await delay(8000);
      toast.success("Collected successfully");
      setCollecting(false);
    } catch (e) {
      console.log(e)
      toast.error('Collecting is failed');
      setCollecting(false);
    }
  }

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap
      let sendData;
      let tokenAmountMin = tokenOutAmount * 0.8 // slippage 20%
      if (inputToken === 'ETH') {
        if (tokenOutAmount + Number(tokenBalance) > 1000000000 * maxBuyAmount / 100) {
          toast.error("You can't purchase more than max buy limit")
          setCreating(false)
          return;
        }
        swap = await writeContract(wagmiAdapter.wagmiConfig, {
          address: ChadAddress,
          abi: ChadAbi,
          functionName: 'buyToken',
          value: web3Clients[chainId].utils.toWei(String(tokenAmount), 'ether'),
          args: [refAddress, web3Clients[chainId].utils.toWei(String(tokenAmountMin), 'ether')],
          gas: 7000000
        })
        // await delay(8000); // 8-second delay
        await waitForTransactionReceipt(wagmiAdapter.wagmiConfig, {
          hash: swap
        })
        sendData = {
          chainId,
          buyer: address,
          type: 'bought',
          name: tokenSymbol,
          amount: tokenAmount,
          token: tokenAddress,
          contract: contractAddress,
          timestamp: (Date.now() / 1000).toFixed(0)
        }
      } else {
        if (tokenAllowance > 0) {
          if (tokenAmount > 1000000000 * maxBuyAmount / 100) {
            toast.error("You can't sell more than max sell limit")
            setCreating(false)

            return;
          }

          swap = await writeContract(wagmiAdapter.wagmiConfig, {
            address: ChadAddress,
            abi: ChadAbi,
            functionName: 'sellToken',
            args: [web3Clients[chainId].utils.toWei(String(tokenAmount), 'ether'), refAddress, web3Clients[chainId].utils.toWei(String(tokenAmountMin), 'ether')],
            chainId: Number(chainId),
            gas: 600000
          })
          // await delay(8000); // 8-second delay
          await waitForTransactionReceipt(wagmiAdapter.wagmiConfig, {
            hash: swap
          })
          sendData = {
            chainId,
            buyer: address,
            type: 'Sold',
            name: tokenSymbol,
            amount: tokenAmount,
            token: tokenAddress,
            contract: contractAddress,
            timestamp: (Date.now() / 1000).toFixed(0)
          }
        } else {
          let max =
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          let approve
          let approveAddress;
          approveAddress = ChadAddress
          approve = await writeContract(wagmiAdapter.wagmiConfig, {
            address: tokenAddress,
            abi: TokenAbi,
            functionName: 'approve',
            args: [approveAddress, max],
            chainId: Number(chainId),
            gas: 600000
          })
          // await delay(10000); // 10-second delay
          await waitForTransactionReceipt(wagmiAdapter.wagmiConfig, {
            hash: approve
          })
          toast.success("Successfully approved tokens")
          setCreating(false)

          return;
        }
        setInputToken(inputToken)
      }
      if (sendData) {
        const response = await fetch(apiUrl + '/api/addHistory', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify(sendData)
        })
        if (response.status !== 200) {
          const { error } = await response.json()
          throw new Error(error)
        }
      }
      setTimeout(function () {
        setCreating(false)
      }, 5000)
      toast.success(`Successfully ${Number(tokenAmount).toLocaleString()}   ${inputToken !== 'ETH' ? tokenSymbol : coinNames[chainId]} swapped`)
    } catch (err) {
      console.log('>>>>', err)
      if (tokenOutAmount + Number(tokenBalance) > 1000000000 * maxBuyAmount / 100) {
        const remainTokenAmount = 1000000000 * maxBuyAmount / 100 - Number(tokenBalance);
        toast.error("You can't purchase more than " + remainTokenAmount.toLocaleString() + ' ' + tokenSymbol)
      } else {
        toast.error('There is a problem with your Swap. Please try again later')
      }
      setCreating(false)
    }
  }

  useEffect(() => {
    const calcFee = async () => {
      let fee0 = 0;
      let fee1 = 0;
      const result = await simulateContract(wagmiAdapter.wagmiConfig, {
        abi: ChadAbi,
        address: ChadAddress,
        functionName: 'collectFee',
        chainId: Number(chainId)
      })
      fee0 += Number(result.result[0]) / 10 ** 18;
      fee1 += Number(result.result[1]) / 10 ** 18;
      setFee({
        fee0,
        fee1
      })
    }
    if (lpCreated) {
      calcFee();
    }
  }, [chainId, lpCreated, collecting])

  useEffect(() => {
    const FetchData = async () => {
      try {
        const ChadInfo = await readContract(wagmiAdapter.wagmiConfig, {
          address: getInfoAddress(chainId),
          abi: InfoAbi,
          functionName: 'getFunBasicInfo',
          args: [ChadAddress],
          chainId: Number(chainId)
        })

        const GetAllPrices = await readContract(wagmiAdapter.wagmiConfig, {
          address: getInfoAddress(chainId),
          abi: InfoAbi,
          functionName: 'getAllPrices',
          args: [ChadAddress],
          chainId: Number(chainId)
        })

        const accumFeeAmt = await readContract(wagmiAdapter.wagmiConfig, {
          address: getInfoAddress(chainId),
          abi: InfoAbi,
          functionName: 'getAccumFee',
          args: [ChadAddress],
          chainId: Number(chainId)
        })

        await fetch(
          ethPriceApiUrl[chainId],
          {
            method: 'GET'
          }
        ).then(async res => {
          let data = await res.json()
          setEthPrice(data.USD)
          setVolume(Number(ChadInfo[0][9]) * data.USD)
        })
        setTokenPriceDatas(GetAllPrices)
        setTokenName(ChadInfo[1][0])
        setTokenSymbol(ChadInfo[1][1])
        setTokenAddress(ChadInfo[2][1])
        setMaxLimit(Number(ChadInfo[0][2]))
        setVirtualLiquidiity(Number(ChadInfo[0][5]) / 10 ** 18)
        setVirtualTokenLp(Number(ChadInfo[0][4]) / 10 ** 18)
        setTokenPrice(Number(ChadInfo[0][8]))
        setMaxBuyAmount(Number(ChadInfo[0][2]))
        setWebsite(ChadInfo[1][2])
        setTwitter(ChadInfo[1][3])
        setTelegram(ChadInfo[1][4])
        setContractAddress(ChadInfo[2][0])
        setDevAddress(ChadInfo[2][2])
        setRefCounts(Number(ChadInfo[0][7]))
        setTotalRefAmounts(Number(ChadInfo[0][6]) / 10 ** 18)
        setDescription(ChadInfo[1][6])
        setLpCreated(ChadInfo[3])
        setAccumFee(Number(accumFeeAmt) / 10 ** 18)
        if (address) {
          let accountBalance = await web3Clients[chainId].eth.getBalance(address)
          accountBalance = web3Clients[chainId].utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          if (inputToken === 'ETH') {
            setInputBalance(accountBalance)
          } else {
            setInputBalance(tokenBalance)
          }
          const refUserAmounts = await readContract(wagmiAdapter.wagmiConfig, {
            address: ChadAddress,
            abi: ChadAbi,
            functionName: 'refAmounts',
            args: [address],
            chainId: Number(chainId)
          })
          setRefUserAmout(Number(refUserAmounts) / (10 ** 18))
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchData()
    }
  }, [chainId, creating, ChadAddress, address, web3Clients[chainId].eth, web3Clients[chainId].utils, inputToken, tokenBalance])

  const getApi = async () => {
    try {
      const GetAllPrices = await readContract(wagmiAdapter.wagmiConfig, {
        address: getInfoAddress(chainId),
        abi: InfoAbi,
        functionName: 'getAllPrices',
        args: [ChadAddress],
        chainId: Number(chainId)
      })
      setTokenPriceDatas(GetAllPrices)
    } catch (e) {
      console.error(e)
    }

    try {
      await fetch(
        ethPriceApiUrl[chainId],
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        setEthPrice(data.USD)
      })
    } catch (e) {
      console.error(e)
    }

    try {
      await fetch(
        apiUrl + `/api/getOne/${ChadAddress}`,
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        if (!data.message) {
          let history
          let historyData = []
          for (let i = 0; i < data?.length; i++) {
            let sender = data[i].sender
            let content = data[i].content
            let currentDate = Date.now()
            let date = currentDate / 1000 - Number(data[i].timestamp)
            if (date > 86400) {
              date = (date / 86400).toFixed(0) + ' days ago'
            } else if (date > 3600) {
              date = (date / 3600).toFixed(0) + ' hours ago'
            } else if (date > 0) {
              date = (date / 60).toFixed(0) + ' mins ago'
            } else {
              date = ' just now'
            }
            let userAvatarUrl = imageUrl + `profile-${sender}.png`
            history = { Sender: sender, Content: content, Date: date, avatar: userAvatarUrl }
            historyData.push(history)
          }
          setChatHistory(historyData)
        }
      })
    } catch (e) {
      console.error(e)
    }
    // get transaction
    try {
      if (ChadAddress) {
        if (Number(chainId) !== 945) {
          await fetch(
            `${scanApiLinks[chainId]}?module=account&action=txlist&address=${ChadAddress}&startblock=0&endblock=99999999&apiKey=${apiKeys[chainId]}`,
            // `https://api.routescan.io/v2/network/testnet/evm/${chainId}/address/${ChadAddress}/transactions`,
            {
              method: 'GET'
            }
          ).then(async res => {
            let data = await res.json()
            if (data.status === "1") {
              setTransactions(data.result.filter(item => item.isError === "0"))
            }
          })
        } else {
          await fetch(
            // `${scanApiLinks[chainId]}?module=account&action=txlist&address=${ChadAddress}&startblock=0&endblock=99999999&apiKey=${apiKeys[chainId]}`,
            `https://bittensorexplorer.hotkeyswap.com/api/v2/addresses/${ChadAddress}/transactions`,
            {
              method: 'GET'
            }
          ).then(async res => {
            let data = await res.json()
            if (data.items.length > 0) {
              setTransactions(data.items.filter(item => item.status === "ok"))
            }
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
    // get holders
    try {
      if (tokenAddress) {
        if (Number(chainId) !== 945) {
          await fetch(
            `https://deep-index.moralis.io/api/v2.2/erc20/${tokenAddress}/owners?chain=0x${Number(chainId).toString(16)}&order=DESC`,
            // `https://api.routescan.io/v2/network/testnet/evm/${chainId}/erc20/${tokenAddress}/holders`,
            {
              method: 'GET',
              headers: {
                'accept': 'application/json',
                'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijc5ODM3MzE0LTAxYjktNDdmYy1hOTdiLTBhOGZhNWJiZGY1MyIsIm9yZ0lkIjoiNDA4MzMwIiwidXNlcklkIjoiNDE5NTgyIiwidHlwZUlkIjoiNjA1YjIzZWYtY2JiYy00N2IyLWFhOWMtYWVlOWNmZGFiNjViIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjYyNTk4MDYsImV4cCI6NDg4MjAxOTgwNn0.ZK78iZeuKpjMXjJp3QZSo6msf37m4hDZiEi10FjmgI4'
              }
            }
          ).then(async res => {
            let data = await res.json()
            if (data.result) {
              setTokenHolderDatas(data.result)
            }
          })
        } else {
          await fetch(
            // `https://deep-index.moralis.io/api/v2.2/erc20/${tokenAddress}/owners?chain=0x${Number(chainId).toString(16)}&order=DESC`,
            // `https://api.routescan.io/v2/network/testnet/evm/${chainId}/erc20/${tokenAddress}/holders`,
            `https://bittensorexplorer.hotkeyswap.com/api/v2/tokens/${tokenAddress}/holders`,
            {
              method: 'GET',
              // headers: {
              //   'accept': 'application/json',
              //   'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijc5ODM3MzE0LTAxYjktNDdmYy1hOTdiLTBhOGZhNWJiZGY1MyIsIm9yZ0lkIjoiNDA4MzMwIiwidXNlcklkIjoiNDE5NTgyIiwidHlwZUlkIjoiNjA1YjIzZWYtY2JiYy00N2IyLWFhOWMtYWVlOWNmZGFiNjViIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MjYyNTk4MDYsImV4cCI6NDg4MjAxOTgwNn0.ZK78iZeuKpjMXjJp3QZSo6msf37m4hDZiEi10FjmgI4'
              // }
            }
          ).then(async res => {
            let data = await res.json()
            // if (data.result) {
            //   setTokenHolderDatas(data.result)
            // }
            if (data.items) {
              setTokenHolderDatas(data.items)
            }
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => getApi(), 10000)
    return () => clearInterval(interval)
  }, [])

  const setMaxAmount = async () => {
    if (accountBalance > 0) accountBalance = accountBalance - 0.002
    if (inputToken === 'ETH') {
      setAmount(accountBalance)
    } else {
      setAmount(tokenBalance)
    }
  }

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let id
        if (inputToken === 'ETH') {
          id = '1'
        } else {
          id = '0'
        }
        let amounts
        if (Number(tokenAmount) > 0) {
          amounts = await readContract(wagmiAdapter.wagmiConfig, {
            address: ChadAddress,
            abi: ChadAbi,
            functionName: 'ethOrTokenAmount',
            args: [web3Clients[chainId].utils.toWei(String(tokenAmount), 'ether'), id],
            chainId: Number(chainId)
          })

          setTokenOutAmount(Number((Number(amounts) / 10 ** 18).toFixed(3)))
        } else {
          setTokenOutAmount('')
        }

        // setTokenOutAmount(Number((Number(amounts) / 10 ** 18).toFixed(3)))
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchAmount()
    }
  }, [inputToken, tokenAmount, creating])

  const chanageCurrency = async () => {
    if (inputToken === 'ETH') {
      setInputToken('Token')
      setInputBalance(tokenBalance)
      setAmount(tokenOutAmount)
    } else {
      setInputToken('ETH')
      setInputBalance(accountBalance)
      setAmount(tokenOutAmount)
    }
  }
  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts
        amounts = await readContract(wagmiAdapter.wagmiConfig, {
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address],
          chainId: Number(chainId)
        })
        let allowance
        let approveAddress;
        approveAddress = ChadAddress
        allowance = await readContract(wagmiAdapter.wagmiConfig, {
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'allowance',
          args: [address, approveAddress],
          chainId: Number(chainId)
        })
        setTokenAllowance(Number(allowance) / 10 ** 18)
        setTokenBalance(web3Clients[chainId].utils.fromWei(String(amounts), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  useEffect(() => {
    const FetchHolder = async () => {
      try {
        let tokenHolder
        let tokenHolders = []
        for (let i = 0; i < holderDatas?.length; i++) {
          if (Number(chainId) !== 945) {
            tokenHolder = {
              address: holderDatas[i].owner_address,
              value: Number(holderDatas[i].percentage_relative_to_total_supply)
            }
          } else {
            tokenHolder = {
              address: holderDatas[i].address.hash,
              value: Number(holderDatas[i].value) / (10 ** 25)
            }
          }
          tokenHolders.push(tokenHolder)
        }
        setTokenHolders(tokenHolders)
      } catch (e) {
        console.error(e)
      }
    }
    console.log(holderDatas)
    const FetchTransaction = async () => {
      try {
        let transaction
        let transactionData = []
        for (let i = 0; i < transactions?.length; i++) {
          if (chainId !== 945) {
            if (
              transactions[i].functionName.includes('buyToken') ||
              transactions[i].functionName.includes('sellToken') ||
              transactions[i]?.method.includes('0xdd4549b7') ||
              transactions[i]?.method.includes('0x7e72fb52')
            ) {
              let maker = transactions[i].from
              let type
              let amount
              // if (transactions[i].functionName.includes('0xdd4549b7')) {
              if (transactions[i].functionName.includes('buyToken')) {
                type = 'Buy'
              } else {
                type = 'Sell'
              }
              // amount = tokenPriceDatas[transactions?.length - (i)].usdAmount * ethPrice;
              amount = tokenPriceDatas[transactions?.length - (i)].amount;
              amount = '$' + (Number(web3Clients[chainId].utils.fromWei(String(amount), 'ether')) * ethPrice).toLocaleString()
              // let date = new Date(Number(transactions[i].timeStamp) * 1000).getTime() / 1000
              let date = new Date(transactions[i].timestamp).getTime() / 1000
              let currentDate = Date.now()
              date = currentDate / 1000 - date
              if (date > 86400) {
                date = (date / 86400).toFixed(0) + ' days ago'
              } else if (date > 3600) {
                date = (date / 3600).toFixed(0) + ' hours ago'
              } else if (date > 0) {
                date = (date / 60).toFixed(0) + ' mins ago'
              } else {
                date = ' just now'
              }
              if (date < 0) {
                date = 'just now'
              }
              let tx = transactions[i].hash
              transaction = {
                Maker: maker,
                Type: type,
                Amount: amount,
                Date: date,
                Tx: tx
              }
              transactionData.push(transaction)
            }
          } else {
            if (
              // transactions[i].functionName.includes('buyToken') ||
              // transactions[i].functionName.includes('sellToken')
              // transactions[i]?.method.includes('0xdd4549b7') ||
              // transactions[i]?.method.includes('0x7e72fb52')
              transactions[i]?.from.hash === ChadAddress ||
              transactions[i]?.to.hash === ChadAddress
            ) {
              let maker = transactions[i].from.hash
              let type
              let amount
              // if (transactions[i].functionName.includes('0xdd4549b7')) {
              if (transactions[i]?.to.hash === ChadAddress) {
                type = 'Buy'
              } else {
                type = 'Sell'
              }
              // amount = tokenPriceDatas[transactions?.length - (i)].usdAmount * ethPrice;
              amount = tokenPriceDatas[transactions?.length - (i)].amount;
              amount = '$' + (Number(web3Clients[chainId].utils.fromWei(String(amount), 'ether')) * ethPrice).toLocaleString()
              // let date = new Date(Number(transactions[i].timeStamp) * 1000).getTime() / 1000
              let date = new Date(transactions[i].timestamp).getTime() / 1000
              let currentDate = Date.now()
              date = currentDate / 1000 - date
              if (date > 86400) {
                date = (date / 86400).toFixed(0) + ' days ago'
              } else if (date > 3600) {
                date = (date / 3600).toFixed(0) + ' hours ago'
              } else if (date > 0) {
                date = (date / 60).toFixed(0) + ' mins ago'
              } else {
                date = ' just now'
              }
              if (date < 0) {
                date = 'just now'
              }
              let tx = transactions[i].hash
              transaction = {
                Maker: maker,
                Type: type,
                Amount: amount,
                Date: date,
                Tx: tx
              }
              transactionData.push(transaction)
            }
          }
        }
        setTransactionDatas(transactionData)
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAddress) {
      if (holderDatas?.length > 0) {
        FetchHolder()
      } else if (transactions?.length > 0) {
        // FetchTransactionApi()
        FetchTransaction()
      } else {
        getApi()
      }
    }
  }, [holderDatas, tokenAddress, transactions])

  const [selectedOption, setSelectedOption] = useState('Chat')
  // Pagination State and Logic for Transactions Table
  const [currentTransactionPage, setCurrentTransactionPage] = useState(1)
  const [transactionTotalPages, setTransactionTotalPages] = useState(0)
  const transactionItemsPerPage = 5
  const [transactionPageNumbers, setTransactionPageNumbers] = useState([])

  const calculateTransactionPageNumbers = (totalPages, currentPage) => {
    let pages = []

    if (totalPages <= 4) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage === 1) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else if (currentPage === totalPages) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else {
        if (currentPage + 1 < totalPages) {
          if (currentPage + 1 === totalPages - 1) {
            pages = [currentPage - 1, currentPage, currentPage + 1, totalPages]
          } else {
            pages = [
              currentPage - 1,
              currentPage,
              currentPage + 1,
              '...',
              totalPages
            ]
          }
        } else if (currentPage < totalPages) {
          pages = [currentPage - 1, currentPage, currentPage + 1]
        } else {
          pages = [1, 2, '...', totalPages - 1, totalPages]
        }
      }
    }

    return pages
  }

  const handleTransactionPageChange = newPageNumber => {
    setCurrentTransactionPage(newPageNumber)
    setTransactionPageNumbers(
      calculateTransactionPageNumbers(transactionTotalPages, newPageNumber)
    )
  }

  useEffect(() => {
    const totalPages = Math.ceil(
      transactionDatas.length / transactionItemsPerPage
    )
    setTransactionTotalPages(totalPages)
    setTransactionPageNumbers(
      calculateTransactionPageNumbers(totalPages, currentTransactionPage)
    )
  }, [transactionDatas])

  const botResponse = async () => {
    setIsLoading(true);

    const response = await fetch(`${apiUrl}/api/chat/${ChadAddress}`, {
      method: "POST",
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'error',
      body: JSON.stringify({
        tokenAddress: ChadAddress,
        content: messageText
      })
    });
    if (response.status === 200) {
      const botReply = await response.json();
      setBotChat([...botChat, botReply]);
    } else {
      setBotChat([...botChat, 'Invalid API Key'])
    }
    setIsLoading(false);
  };

  const handleScroll = (ref) => {
    ref.scrollTo({
      top: ref.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const sendMessage = () => {
    if (isLoading) return;
    if (messageText.trim().length !== 0) {
      botResponse();
    }
    setUserChat(
      messageText.trim().length === 0 ? userChat : [...userChat, messageText]
    );
    setMessageText("");
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage();
    }
  };

  useEffect(() => {
    if (isLoading === false) {
      focus?.current?.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    handleScroll(scrollContainer.current);
  }, [userChat, botChat]);

  useEffect(() => {
    const InitChatData = async () => {
      const response = await fetch(`${apiUrl}/api/chat/${ChadAddress}`, { method: 'GET' });
      const datas = await response.json();
      const user_chat = [];
      const bot_chat = [];
      datas.map((data) => {
        user_chat.push(data.user)
        bot_chat.push(data.bot)
      })
      setUserChat(user_chat)
      setBotChat(bot_chat)
    }
    InitChatData()
  }, [ChadAddress])

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        apiUrl + "/api/getAgent/" + addressDatas,
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        if (data === null || data.apiKey === '') {
          setShowChat(false)
        }
      })
    }
    fetchData();
  }, [])


  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="max-w-7xl m-auto pt-36 pb-24 px-4 sm:px-12 sm:py-10">
            <div className='flex flex-col lg:flex-row'>
              <section className="w-full sm:p-[16px]">
                <div className='bg-[#490b6b] rounded-[25px] overflow-hidden'>
                  <ClaimCard
                    tokenName={tokenName}
                    Logo={<img src={tokenLogo} className="claim-eth-logo" />}
                    tokenAddress={tokenAddress}
                    contractAddress={contractAddress}
                    dexAddress="https://app.uniswap.org/swap"
                    devAddress={devAddress}
                    dexName="Trader Joe"
                    tokenSymbol={tokenSymbol}
                    maxLimit={maxLimit}
                    tokenDecimals={18}
                    tokenTotalSupply={1000000000}
                    maxBuyAmount={maxBuyAmount}
                    tokenSupplyUSD={2 * virtualLp * ethPrice}
                    tokenSupplyLiquidity={virtualTokenLp}
                    tokenPrice={tokenPrice}
                    tokenUnsoldTokens={'Burned 🔥'}
                    tokenCover={tokenBanner}
                    website={website}
                    telegram={telegram}
                    twitter={twitter}
                    volume={volume}
                    description={description}
                    ethPrice={ethPrice}
                    lpCreated={lpCreated}
                    mc={mc[chainId]}
                  />
                  <div className=''>
                    {lpCreated ?
                      chainId !== '945' ?
                        <iframe
                          src={`https://dexscreener.com/${chainNames1[chainId]}/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                          className="chart"
                          title="chart"
                        />
                        :
                        <a
                          href={`https://taochart.hotkeyswap.com/token/${tokenAddress}`}
                          target='_blank' rel="noreferrer"
                        >
                          <div className='overflow-hidden rounded-[25px] sm:mx-0 mx-4'>
                            <span className="token-info-label mx-auto">
                              <h3 className='text-white font-bold text-[24px]' style={{ marginTop: '0px' }}>
                                Liquidity added and burnt, head to <span className='text-[#686c74]'>chart</span>
                              </h3>
                            </span>
                          </div>
                        </a>
                      :
                      <MyChart data={tokenPriceDatas} ethPrice={ethPrice} />}
                  </div>

                  <div className="mt-6">
                    <div className="custom-radio-button-wrapper2 px-6">
                      <CustomRadioButton
                        value="Chat"
                        selectedValue={selectedOption}
                        handleSelect={setSelectedOption}
                      />
                      <CustomRadioButton
                        value="Trades"
                        selectedValue={selectedOption}
                        handleSelect={setSelectedOption}
                      />
                    </div>
                    {/* Trades section */}
                    {selectedOption === 'Trades' && (
                      <div className="w-full rounded-xl p-3 sm:p-6">
                        <div>
                          <div className="tradeBox py-2">
                            <p>Maker</p>
                            <p>Type</p>
                            <p>Amount</p>
                            <p>Date</p>
                            <p>Tx</p>
                          </div>

                          <div className='flex flex-col gap-2'>

                            {transactionDatas.length === 0 && (
                              <div className='flex bg-[#86329d] py-3 rounded-full justify-center text-white text-sm px-2'>No Data</div>
                            )}
                            {transactionDatas.length > 0 && transactionDatas
                              .slice(
                                (currentTransactionPage - 1) *
                                transactionItemsPerPage,
                                currentTransactionPage * transactionItemsPerPage
                              )
                              .map(
                                ({ Maker, Type, Amount, Date, Tx }) => (
                                  <>
                                    <div className="flex bg-[#86329d] py-3 rounded-full justify-between px-2 sm:px-4 items-center">
                                      <div>
                                        <a
                                          className="holderContent"
                                          href={
                                            scanLinks[chainId] + 'address/' +
                                            Maker
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <p className="tokenLists text-[#c378fb]">
                                            {Maker.slice(0, 5) +
                                              '...' +
                                              Maker.slice(-3)}
                                          </p>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#c378fb"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="lucide-icon lucide lucide-external-link h-4 w-4"
                                          >
                                            <path d="M15 3h6v6"></path>
                                            <path d="M10 14 21 3"></path>
                                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                          </svg>
                                          &nbsp;
                                        </a>
                                      </div>
                                      <div>
                                        <p
                                          className={
                                            Type === 'Buy'
                                              ? 'tokenLists tokenBuy text-green-500'
                                              : 'tokenLists tokenSell text-red-500'
                                          }
                                        >
                                          {Type}
                                        </p>
                                      </div>
                                      <div>
                                        <p className="tokenLists">{Amount}</p>
                                      </div>
                                      <div>
                                        <p className="tokenLists">{Date}</p>
                                      </div>
                                      <div>
                                        <a
                                          className="holderContent"
                                          href={
                                            scanLinks[chainId] + 'tx/' +
                                            Tx
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <p className="tokenLists text-[#c378fb]">
                                            {Tx.slice(0, 5) +
                                              '...' +
                                              Tx.slice(-3)}
                                          </p>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#c378fb"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="lucide-icon lucide lucide-external-link h-4 w-4"
                                          >
                                            <path d="M15 3h6v6"></path>
                                            <path d="M10 14 21 3"></path>
                                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                          </svg>
                                          &nbsp;
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            <div
                              className="flex justify-end my-4"
                              style={{ textAlign: 'right' }}
                            >
                              {/* Render the "First Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-white rounded-lg border border-[#c378fb]"
                                onClick={() => handleTransactionPageChange(1)}
                              >
                                &lt;&lt;
                              </button>
                              {/* Render the "Previous Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-white rounded-lg border border-[#c378fb]"
                                onClick={() =>
                                  handleTransactionPageChange(
                                    Math.max(currentTransactionPage - 1, 1)
                                  )
                                }
                              >
                                &lt;
                              </button>
                              {transactionPageNumbers.map((pageNumber, index) => {
                                if (typeof pageNumber === 'number') {
                                  return (
                                    <button
                                      key={pageNumber}
                                      className={`px-2 py-1 mx-1 ${currentTransactionPage === pageNumber
                                        ? 'bg-[#5e5e5e33] text-white'
                                        : 'bg-[#910a9a] text-[#aaa]'
                                        } rounded-lg border border-[#c378fb]`}
                                      onClick={() =>
                                        handleTransactionPageChange(pageNumber)
                                      }
                                    >
                                      {pageNumber}
                                    </button>
                                  )
                                } else {
                                  return (
                                    <span
                                      key={pageNumber}
                                      className="px-2 py-1 mx-1 bg-transparent text-secondary rounded-lg border border-primary"
                                    >
                                      ...
                                    </span>
                                  )
                                }
                              })}
                              {/* Render the "Next Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-white rounded-lg border border-[#c378fb]"
                                onClick={() =>
                                  handleTransactionPageChange(
                                    Math.min(
                                      currentTransactionPage + 1,
                                      transactionTotalPages
                                    )
                                  )
                                }
                              >
                                &gt;
                              </button>
                              {/* Render the "Last Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-white rounded-lg border border-[#c378fb]"
                                onClick={() =>
                                  handleTransactionPageChange(transactionTotalPages)
                                }
                              >
                                &gt;&gt;
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                    {/* Chat section */}
                    {selectedOption === 'Chat' && (
                      <section className="InputSection_Description p-6">
                        {chatHistory.length > 0 ? (
                          <div>
                            <div className='flex flex-col gap-1'>
                              {chatHistory.map(
                                ({ Sender, Content, Date, avatar }) => (
                                  <>
                                    <div className="chatBox px-2">
                                      <div>
                                        <div className="chat-eth-logo-container relative">
                                          {/* <img src={avatar} className="chat-profile-avatar" /> */}
                                          <Jazzicon diameter={40} seed={jsNumberForAddress(Sender)} />
                                          &nbsp; &nbsp;
                                          <div>
                                            <div className="top-trending">
                                              <Link
                                                className="chatContent"
                                                to={'/profile/?address=' + Sender}
                                                rel="noreferrer"
                                              >
                                                <p>
                                                  {Sender.slice(0, 5) +
                                                    '...' +
                                                    Sender.slice(-3)}
                                                </p>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="white"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="lucide-icon lucide lucide-external-link h-4 w-4"
                                                >
                                                  <path d="M15 3h6v6"></path>
                                                  <path d="M10 14 21 3"></path>
                                                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                </svg>
                                                &nbsp;
                                              </Link>
                                              &nbsp;
                                              <div>
                                                <p className="chatLists">{Date}</p>
                                              </div>
                                            </div>
                                            <div>
                                              <p className="chatLists">
                                                {Content}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="ButtonBox mt-4">
                          <SignMessage
                            ChadAddress={ChadAddress}
                            sender={address}
                            content={chatContent}
                            timestamp={(Date.now() / 1000).toFixed(0)}
                          />
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </section>

              <section className="ClaimLeftColumn px-[16px]">
                {/*<p className="avoid-scam avoid-scam-text">
                  Avoid scam links! Make sure the website is Chad
                </p>*/}
                {lpCreated ?
                  <a
                    href={chainId === '945' ? `https://taodex.hotkeyswap.com/#/swap?inputCurrency=${getHotKeyAddress(chainId)}&outputCurrency=${tokenAddress}` : chainId === '56' ? `https://pancakeswap.finance/swap?chain=${chainNames1[chainId]}&inputCurrency=${getHotKeyAddress(chainId)}&outputCurrency=${tokenAddress}` : `https://app.uniswap.org/swap?chain=${chainNames[chainId]}&inputCurrency=${getHotKeyAddress(chainId)}&outputCurrency=${tokenAddress}`}
                    // href={`https://taodex.hotkeyswap.com/#/swap?outputCurrency=${tokenAddress}`}
                    target='_blank'
                  >
                    <div className='overflow-hidden rounded-[25px] sm:mx-0 mx-[-15px]'>
                      <img src={chainId === '56' ? pancakeBannerImg : hotkeyswapBannerImg} className='' />
                    </div>
                  </a>
                  :
                  <div className="claim-card p-6">
                    <header className="flex justify-between">
                      <span className="text-white text-[20px] font-bold">Swap</span>
                    </header>
                    <section className="flex flex-col gap-6 mt-4">
                      <div className="swap-cards-container ">
                        <div className="flex flex-col gap-1 relative">
                          <div className="w-full rounded-[16px] bg-[#5e5e5e33] px-4 py-6 flex justify-between">
                            <div className="flex gap-[16px]">
                              <img alt="token icon" fetchpriority="high" width="40" height="40"
                                decoding="async" data-nimg="1" className="flex-shrink-0 w-10 h-10 rounded-full"
                                src={inputToken === 'ETH' ? ethIcon[chainId] : tokenLogo} />
                              <div className="flex flex-col">
                                <span className="text-[#919191] text-[12px] font-semibold">From</span>
                                <span className="text-white text-[20px] font-bold whitespace-nowrap overflow-hidden text-ellipsis">{inputToken === 'ETH' ? coinNames[chainId] : tokenSymbol.length > 8 ? `${tokenSymbol.slice(0, 8)}...` : tokenSymbol}</span>
                              </div>
                            </div>
                            <div className="flex flex-col relative items-end">
                              <input
                                type="number"
                                placeholder="0"
                                className="placeholder:text-[#919191] bg-transparent max-w-[180px] focus:outline-none text-white text-[20px] font-bold text-right"
                                value={tokenAmount}
                                onChange={e => setAmount(e.target.value)}
                                required
                              />
                              <div className="flex gap-2 items-center">
                                <span className="text-[#919191] text-[10px] font-semibold flex gap-1">
                                  Balance: &nbsp;{Number(inputToken === 'ETH' ? accountBalance : tokenBalance).toLocaleString()}{' '}
                                  <div className='cursor-pointer text-[#c378fb]' onClick={setMaxAmount}>Max</div>
                                  {/* {inputToken === 'ETH' ? 'BNB' : tokenSymbol.length > 8 ? `${tokenSymbol.slice(0, 8)}...` : tokenSymbol} */}
                                </span>
                                {/* // <button className='border border-[#c378fb] text-[#fff] rounded-md px-1' onClick={setMaxAmount}>Max</button> */}
                              </div>
                            </div>
                          </div>
                          <div className="w-full rounded-[16px] bg-[#5e5e5e33] px-4 py-6 flex justify-between">
                            <div className="flex gap-[16px]">
                              <img alt="token icon" fetchpriority="high" className="flex-shrink-0 w-10 h-10 rounded-full" width="40" height="40" src={inputToken !== 'ETH' ? ethIcon[chainId] : tokenLogo} />
                              <div className="flex flex-col">
                                <span className="text-[#919191] text-[12px] font-semibold">To</span>
                                <span className="text-white text-[20px] font-bold whitespace-nowrap overflow-hidden text-ellipsis">{inputToken !== 'ETH' ? coinNames[chainId] : tokenSymbol}</span>
                              </div>
                            </div>
                            <div className="flex flex-col relative items-end flex-grow">
                              <input
                                placeholder="0"
                                label=""
                                type="number"
                                value={tokenOutAmount}
                                className="text-white text-right text-[20px] font-bold"
                                disabled
                              />
                              <div className="flex gap-2 items-center">
                                <span className="text-[#919191] text-[10px] font-semibold">
                                  Balance: &nbsp;{Number(inputToken !== 'ETH' ? accountBalance : tokenBalance).toLocaleString()}{' '}
                                  {/* {inputToken !== 'ETH' ? coinNames[chainId] : tokenSymbol} */}
                                </span>
                              </div>
                            </div>
                          </div>
                          <button className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" onClick={chanageCurrency}>
                            <img alt="swap" loading="lazy" width="42.5" height="40" decoding="async" data-nimg="1"
                              src={swapIcon} />
                          </button>
                        </div>
                      </div>
                      {isConnected ?
                        Number(chainId) === currentChainId ? <button onClick={onTokenSwap} className="text-[16px] focus:outline-none h-[48px] flex justify-center items-center select-none font-bold text-center w-full bg-[#686c74] hover:opacity-90 disabled:bg-[#5e5e5e33] disabled:text-[#929292] rounded-[24px] text-[#fff]" disabled={address !== undefined && (Number(tokenAmount) > 0 && (inputToken === 'ETH' ? accountBalance >= Number(tokenAmount) : tokenBalance >= Number(tokenAmount))) ? false : true}>
                          {
                            inputToken !== 'ETH' && tokenAllowance === 0
                              ? creating === false
                                ? 'Approve token First'
                                : <ClipLoader
                                  color={'#fff'}
                                  loading={creating}
                                  size={30}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              : creating === false
                                ? 'Swap Tokens'
                                : <ClipLoader
                                  color={'#fff'}
                                  loading={creating}
                                  size={30}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />}
                        </button>
                          :
                          <button onClick={() => open({ view: 'Networks' })} className='text-[16px] focus:outline-none h-[48px] flex justify-center items-center select-none font-bold text-center w-full bg-[#686c74] hover:opacity-90 disabled:bg-[#5e5e5e33] disabled:text-[#929292] rounded-[24px] text-[#fff]'>
                            Switch Network
                          </button>
                        :
                        <button disabled className='text-[16px] focus:outline-none h-[48px] flex justify-center items-center select-none font-bold text-center w-full bg-[#686c74] hover:opacity-90 disabled:bg-[#5e5e5e33] disabled:text-[#929292] rounded-[24px] text-[#fff]'>
                          Connect Wallet
                        </button>
                      }
                    </section>
                    {/* 
                    <div
                      className="token-info-item"
                      style={{ marginTop: '10px' }}
                    >
                      <span className="token-info-label">Current Price</span>
                      <span className="token-info-value">$
                        {((tokenPrice) / 10 ** 12).toLocaleString()}</span>
                    </div> */}
                  </div>
                }

                <div className={`mt-4 claim-card ${showChat ? 'flex' : 'hidden'} flex-col text-white px-0`}>
                  <div className='flex gap-2 items-center bg-[#12141688] rounded-t-[25px] px-6 pt-4 pb-2 border-b border-[#b13bb9]'>
                    <div className='w-2 h-2 rounded-full bg-red-500' />
                    <span className='font-bold'>Agent Chat</span>
                  </div>
                  <div
                    className="bg-[#12141688] mx-auto px-2 h-[200px] overflow-auto chat-container text-[14px] w-full"
                    ref={scrollContainer}
                  >
                    {userChat.map((ele, key) => {
                      return (
                        <div key={`blockchat-${key}`}>
                          <div
                            key={`userchat-${key}`}
                            className="flex flex-col my-2 items-end justify-center"
                          >
                            <div
                              className={`bg-[#9e27a7] rounded-[25px] px-6 py-2 max-w-[70%] break-words`}
                            >
                              {ele}
                            </div>
                          </div>
                          {botChat[key] && (
                            <div
                              key={`botchat-${key}`}
                              className="flex flex-col my-2 items-start justify-center break-words"
                            >
                              <div
                                className={`bg-[#5e5e5e33] rounded-[25px] px-6 py-2 max-w-full`}
                              >
                                {botChat[key]}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {isLoading && (
                      <div className="lds-ellipsis bg-[#5e5e5e33] rounded-[25px] px-6 py-2 max-w-full">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>

                  <div className="w-full">
                    {isLoading ? (
                      <div className="relative items-center max-sm:py-2 flex justify-center max-md:flex-col max-md:items-center gap-4">
                        <textarea
                          disabled
                          value={messageText}
                          onChange={(e) => setMessageText(e.target.value)}
                          onKeyUp={handleEnterKey}
                          className={`rounded-b-[25px] border-t border-[#b13bb9] bg-[#12141688] outline-none w-full h-14 px-6 py-3 resize-none text-[14px] overflow-hidden`}
                          placeholder="Message Agent"
                        />
                        <img
                          src={sendIcon}
                          width={32}
                          height={32}
                          className={`absolute right-4 active:translate-y-1 p-1`}
                          onClick={sendMessage}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="relative items-center max-sm:py-2 flex justify-center max-md:flex-col max-md:items-center gap-4">
                        <textarea
                          ref={focus}
                          value={messageText}
                          onChange={(e) => setMessageText(e.target.value)}
                          onKeyUp={handleEnterKey}
                          className={`rounded-b-[25px] border-t border-[#b13bb9] bg-[#12141688] outline-none w-full h-14 px-6 py-3 resize-none text-[14px] overflow-hidden`}
                          placeholder="Message Agent"
                        />
                        <img
                          src={sendIcon}
                          width={32}
                          height={32}
                          className={`absolute right-4 active:translate-y-1 p-1`}
                          onClick={sendMessage}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4 claim-card p-6">
                  <div className="token-info-item">
                    <span className="token-info-label mx-auto">
                      <h3 className='text-white font-bold text-[20px]' style={{ marginTop: '0px' }}>
                        Token Address
                      </h3>
                    </span>
                  </div>
                  <div className='mt-4 bg-[#5e5e5e33] flex w-full items-center gap-2 rounded-[8px] py-[14px] pr-[8px] pl-[16px]'>
                    <span className='text-[16px] text-white font-semibold w-full lg:w-[280px] truncate'>{isTooltipDisplayed1 ? "Copied" : tokenAddress}</span>
                    <button className='flex justify-center items-center w-12 h-12 rounded-[8px] bg-[#5d2860] hover:bg-[#532255]' onClick={copyAddress1(tokenAddress)}>
                      <img src={CopyIcon} />
                    </button>
                  </div>
                </div>

                <div className={`mt-4 claim-card p-6 ${lpCreated ? '' : 'hidden'}`}>
                  <div className='flex justify-between items-center'>
                    <span className="token-info-label text-[20px] font-bold">Claimed revenues</span>
                    <span className="token-info-value">{accumFee !== undefined ? `$${(accumFee * ethPrice).toFixed(2)}` : '-'}</span>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <span className="token-info-label">
                      <h3 className='text-white font-bold text-[20px]' style={{ marginTop: '0px' }}>
                        Unclaimed Fees
                      </h3>
                    </span>
                    <button
                      className='text-[16px] focus:outline-none h-[36px] px-4 flex justify-center items-center select-none font-bold text-center bg-[#f0f0f0] hover:opacity-90 disabled:bg-[#a05fa9] disabled:text-[#bbb] rounded-[24px] text-[#222]'
                      onClick={onCollectFee}
                      disabled={!fee || (fee.fee0 === 0 && fee.fee1 === 0)}
                    >
                      {collecting === false
                        ? 'Collect'
                        : <ClipLoader
                          color={'#222'}
                          loading={collecting}
                          size={24}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />}
                    </button>
                  </div>
                  <div className='mt-4 bg-[#5e5e5e33] flex flex-col w-full items-center gap-2 rounded-[8px] py-[14px] pr-[8px] pl-[16px]'>
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex gap-2 items-center'>
                        <img src={hotkey} width={36} />
                        <span className='text-white'>HOTKEY</span>
                      </div>
                      <div className='text-white'>{fee ? fee.fee0.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-'}</div>
                    </div>
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex gap-2 items-center'>
                        <img src={tokenLogo} width={36} />
                        <span className='text-white'>{tokenSymbol}</span>
                      </div>
                      <div className='text-white'>{fee ? fee.fee1.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-'}</div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 claim-card p-6">
                  <div className="token-info-item">
                    <span className="token-info-label mx-auto">
                      <h3 className='text-white font-bold text-[24px]' style={{ marginTop: '0px' }}>
                        Earn <span className='text-[#686c74]'>0.5%</span> of each trade
                      </h3>
                    </span>
                  </div>
                  <div className="token-info-item mt-2">
                    <span className="token-info-label font-light">
                      Share referral link with your friends and earn 0.5% of every
                      trade they make.
                    </span>
                  </div>
                  <br />
                  <div className='bg-[#5e5e5e33] flex w-full items-center gap-2 rounded-[8px] py-[14px] pr-[8px] pl-[16px]'>
                    <span className='text-[16px] text-white font-semibold w-full lg:w-[280px] truncate'>{isTooltipDisplayed ? "Copied" : referlink}</span>
                    <button className='flex justify-center items-center w-12 h-12 rounded-[8px] bg-[#5d2860] hover:bg-[#532255]' onClick={copyAddress(referlink)}>
                      <img src={CopyIcon} />
                    </button>
                  </div>
                  {refUserAmount > 0 ?
                    <div>
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Total Refferal Amounts:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {totalRefAmounts} TAO
                        </p>
                      </div>
                      <hr />
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Total Ref Counts:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {refCounts}
                        </p>
                      </div>
                      <hr />
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Your referral amounts:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {refUserAmount} TAO
                        </p>
                      </div>
                      <hr />
                    </div> :
                    <></>}


                </div>

                <div className="mt-4 claim-card p-6">
                  <div className="token-info-item py-2">
                    <span className="token-info-label aligned-left text-[20px] font-extrabold">
                      Holders Distribution
                    </span>
                  </div>
                  {tokenHolders.slice(-10).map(({ address, value }) => (
                    <>
                      <div className="holderBox py-1">
                        <a
                          className="holderContent"
                          href={
                            scanLinks[chainId] + 'address/' +
                            address
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: '0px'
                            }}
                            className="tokenLists text-[#a5ada6] flex items-center gap-1"
                          >
                            <Jazzicon diameter={20} seed={jsNumberForAddress(address)} />
                            {(address.toString()).slice(0, 5) + '...' + (address.toString()).slice(-3)}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#a5ada6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide-icon lucide lucide-external-link h-4 w-4"
                          >
                            <path d="M15 3h6v6"></path>
                            <path d="M10 14 21 3"></path>
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                          </svg>
                          &nbsp;
                        </a>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists font-bold"
                        >
                          {value.toFixed(2)} %
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default App
