/* eslint-disable import/no-anonymous-default-export */

export default {
    multicallAddress: {
        1: '0x6922c82379EC6b4F86061551B552B02C1063D8D5',
        56: '0x6922c82379EC6b4F86061551B552B02C1063D8D5',
        11155111: '0x39520b5161CC8A95ca32966b28100Ea033890404',
        137: '0x1156256625faa48840Aea2Cd6f94518FDB4370fB',
        8453: '0x6922c82379EC6b4F86061551B552B02C1063D8D5',
        945: '0x2fCcDF038f24E798420eF8aeAa8acaf565F87628',
        42161: '0x6922c82379EC6b4F86061551B552B02C1063D8D5'
    },
    factoryAddress: {
        1: '0xf09a5693CDeddc59Dd2916633150B6E4A577E2D3',
        56: '0xf09a5693CDeddc59Dd2916633150B6E4A577E2D3',
        11155111: '0x70852b0990671A3fD83c34760394b1789708e01a',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0xf09a5693CDeddc59Dd2916633150B6E4A577E2D3',
        945: '0x9AF0507038544ACE000b8041ab22c5CF2EB6c9b9',
        42161: '0xf09a5693CDeddc59Dd2916633150B6E4A577E2D3'
    },
    infoAddress: {
        56: '0x3BE0De225e1Cd3559E34d8909FF42C95F233D8ed',
        1: '0x3BE0De225e1Cd3559E34d8909FF42C95F233D8ed',
        945: '0x76Eb9577f013c0801441ab9Ac12EaC5f7De7563B',
        8453: '0x3BE0De225e1Cd3559E34d8909FF42C95F233D8ed',
        42161: '0x3BE0De225e1Cd3559E34d8909FF42C95F233D8ed'
    },
    hotKeyAddress: {
        945: '0x8E65950C7904b92a70B11a63765A5012445731A9',
        8453: '0x374Ad0440Cd5c8E1A8412C156BeeEcE108EF4bB6',
        56: '0x374Ad0440Cd5c8E1A8412C156BeeEcE108EF4bB6',
        42161: '0x374Ad0440Cd5c8E1A8412C156BeeEcE108EF4bB6',
        1: '0x018DD3A0Dd7F213cc822076b3800816d3CE1ED86'
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        945: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        11155111: '0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
        945: '0x5263738a73CB51b95f789dd035493A80672372B7',
        42161: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    },
    routerAddress: {
        945: {'Hotkeyswap': '0x95CED19A5368D602d78f92be5f4538aD61c9DBF3'},
        1: {
            'Uniswap': '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', 
        },
        56: {
            'Uniswap': '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        },
        11155111: {'Uniswap': '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008'},
        137: {'Uniswap': '0xedf6066a2b290C185783862C7F4776A2C8077AD1'},
        8453: {
            'Uniswap': '0x2626664c2603336E57B271c5C0b26F421741e481',
        },
        42161: {
            'Uniswap': '0x2626664c2603336E57B271c5C0b26F421741e481',
        },
        
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        945: '0x0000000000000000000000000000000000000000',
    },
}